import React, { Component } from 'react'

class ExpertiseSectionComponent extends Component {
    render() {
        return (
            <>
                <h1>Expertise</h1>
                <h4>Programming/Scripting Languages</h4>
                <ul>
                    <li>Python</li>
                    <li>Java</li>
                    <li>C#</li>
                    <li>C++</li>
                    <li>VB.NET</li>
                    <li>Javascript with strong knowledge of HTML/CSS</li>
                    <li>Swift</li>
                </ul>
                <h4>A Full Stack under my arsenal</h4>
                <ul>
                    <li>Front-end: React</li>
                    <li>Back-end: Spring Boot with Hibernate, ASP.NET </li>
                    <li>Database: Oracle, MySql</li>
                </ul>
                <h4>Cloud Computing with AWS</h4>
                <ul>
                    <li>Deploying applications with Elastic Beanstalk</li>
                    <li>Front-end from A-Z with AWS Route 53, S3</li>
                    <li>Create and manage databases with AWS RDS</li>
                </ul>
                <h4>Other</h4>
                <ul>
                    <li>Tensorflow for ML/AI </li>
                    <li>Unity for Game Dev/VR</li>
                    <li>Blender as the modeling software of choice</li>
                </ul>
            </>
        )
    }
}

export default ExpertiseSectionComponent