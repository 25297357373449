import React, { Component } from 'react'

class ExperienceSectionComponent extends Component {
    render() {
        return (
            <>
                <h1>Experience</h1>
                <h4>Graduate Teaching Assistant at University of Wyoming(January 2022 - December 2023)</h4>
                <ul>
                    <li>Supervised labs and graded submissions for 3 sections of 90 students total</li>
                </ul>
                <h4>Software Developer at Arikkan Inc (March 2019 - January 2022)</h4>
                <ul>
                    <li>Using .NET frameworks, develop and maintain election software and websites for 4 states: WY, MO, MS and IA</li>
                    <li>Create WinForms application using VB.NET</li>
                    <li>Create websites and web services using C# and VB.NET</li>
                    <li>Manage SQL and Oracle databases on a regular basis</li>
                </ul>
                <h4>Software Developer at Chaves Consulting (2017 - September 2021)</h4>
                <ul>
                    <li>Work remotely as part of partnership between Arikkan Inc and Chaves Consulting</li>
                    <li>In RPG II, maintain and update Oregon's Municipal/Justice Courts Software Application per client's requests</li>
                </ul>
            </>
        )
    }
}

export default ExperienceSectionComponent