import React, {Component} from 'react'
import ProfessionalSectionComponent from './ProfessionalSectionComponent'
import IntroductionComponent from './IntroductionComponent'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class BodyComponent extends Component {
    render() {
        return (
            <div className="body">
                <IntroductionComponent/>                
                <ProfessionalSectionComponent/>                         
            </div>
        )
    }
}

export default BodyComponent