import React, {Component} from 'react'

class HeaderComponent extends Component {
    render() {
        return (
            <div className="bg">
                <div className="headerText"> ~Welcome to my Website~ <br/> I'm Long </div>
            </div>         
        )
    }
}

export default HeaderComponent