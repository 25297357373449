import React, { Component } from 'react'
import YouTube from 'react-youtube';

class ProjectsSectionComponent extends Component {
    render() {
        return (
            <>
                <h1>Projects</h1>

                <h4>Birthday Calendar App</h4>
                <ul>
                    <li>A simple full stack web app to add birthday reminders and get notified as the precious moment approaches.</li>
                    <li>Stack included MySQL database, Spring Boot server with front end created with React</li>
                    <li>Hosted on AWS using S3, Elastic Beanstalk and RDS </li>
                    <li>Used libraries and third party services (eg: Thymeleaf, Twilio ...) to send email and SMS from back end server. </li>
                </ul>
                <h4>Glacier 45 App</h4>
                <ul>
                    <li>Designed and developed an iOs and Android demo app for Glacier 45 Distillery to promote business and products.</li>
                    <li>Use Apple's and Google's ML frameworks to detect and interact with product labels</li>
                    <li>Link on AppStore: <a href="https://apps.apple.com/us/app/g45/id1488276484" target="_blank">Link</a> </li>
                    <li>Link on Google Play: <a href="https://play.google.com/store/apps/details?id=com.arikkan.g45" target="_blank">Link</a> </li>
                </ul>
                <h4>Flappy Bird AI</h4>
                <ul>
                    <li>Coded in Python using Pygame</li>
                    <li>Implemented Q-Learning</li>
                    <li>AI can reach score of 500+, something very hard for most human players to achieve</li>
                    <li>Presentation video: <br /> <YouTube videoId="mBAfdiskKIE" /> </li>
                </ul>
                <h4>Virtual Reality</h4>
                <ul>
                    <li>Created a short escape-the-room puzzle game in Unity using SteamVR</li>
                    <li>Presentation video: <br /> <YouTube videoId="DaVoeYqPR2M" /> </li>
                </ul>

            </>
        )
    }
}

export default ProjectsSectionComponent