import React, { Component } from 'react'

function IntroductionComponent() {
    return (
        <p>
            Hello, my name is Long!<br /><br />

            I would like to first extend a word of gratitude for your time checking out this website. It is not only a place for me to showcase my skill but also to express myself. I hope you a wonderful stay. <br /><br />

            When we were kids, we all had great dreams. Many wanted to win a Nobel prize, some wanted to cure cancer, while others wanted to solve world hunger.
            As we grow up, those dreams gradually fade away as we pursue on a future more realistic. However, mine has not just yet. Inside me, there is still a spark constantly striking,
            yearning to one day kindle into a wildfire. I dream of doing something big, something that is much bigger than myself. <br /><br />

            I am interested in fullstack Software Engineer, with a hobby/passion in Game Development.
            Below, you will find details of my past projects. It ranges from a fullstack webapp, to mobile dev, with even a bit of Machine Learning and Virtual Reality.
            I jokingly call myself "Jack of all trades, master of none" as I like to broaden my horizon and try out everything this field has to offer.
            Nevertheless, I am ready for a new challenge, to dive deep into something and actually master it. If you have an opening that fits my background, please feel free to talk to me, you won't regret it.
            You can find me on LinkedIn at <a href="https://www.linkedin.com/in/lanimatran/" target="_blank"> linkedin.com/in/lanimatran</a> <br /><br />

            Words are just words until one puts them into actions. Therefore, without further ado,
            please continue right ahead:
        </p>
    )
}

export default IntroductionComponent