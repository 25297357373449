import React, { Component } from 'react'
import EducationSectionComponent from './ProfessionalSection/EducationSectionComponent'
import ExpertiseSectionComponent from './ProfessionalSection/ExpertiseSectionComponent'
import ProjectsSectionComponent from './ProfessionalSection/ProjectSectionComponent'
import ExperienceSectionComponent from './ProfessionalSection/ExperienceSectionComponent'

class ProfessionalSectionComponent extends Component {
    render() {
        return (
            <>
                <ProjectsSectionComponent />
                <ExperienceSectionComponent />
                <ExpertiseSectionComponent />
                <EducationSectionComponent />
                {/* <div>Contact Me</div> */}
            </>
        )
    }
}

export default ProfessionalSectionComponent