import React, { Component } from 'react'

class EducationSectionComponent extends Component {
    render() {
        return (
            <>
                <h1>Education</h1>
                <b>B.S. Computer Science with Business Concentration - University of Wyoming(Dec 2018)</b>
                <ul>
                    <li>Computer Science Major</li>
                    <li>Business Minor</li>
                    <li>GPA: 3.9 / 4.0</li>
                </ul>
                <b>M.S. Computer Science - University of Wyoming(Dec 2023)</b>
                <ul>
                    <li>Computer Science</li>
                    <li>GPA: 3.8 / 4.0</li>
                </ul>
            </>
        )
    }
}

export default EducationSectionComponent