import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderComponent from './Components/HeaderComponent'
import BodyComponent from './Components/BodyComponent'

function App() {
  return (
    <>
      <HeaderComponent/>
      <BodyComponent/>
    </>
  );
}

export default App;
